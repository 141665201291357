import { Component } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AudioReport';
  
  images = [
    {title: 'Consulter un rapport', short: 'Consulter un rapport', src: '../assets/images/ReportRead.png'},
    {title: 'Exporter en PDF', short: 'Exporter en PDF', src: '../assets/images/PDFGenerator.png'},
    {title: 'Modifier un rapport', short: 'Modifier un rapport', src: '../assets/images/ReportWrite1.png'},
    {title: 'Modifier un rapport', short: 'Modifier un rapport', src: '../assets/images/ReportWrite2.png'},
    {title: 'Modifier un graphique', short: 'Mettre à jour un graphique', src: '../assets/images/Graphics.gif', class: 'gif'},
    {title: 'Lister les rapports', short: 'Lister les rapport', src: '../assets/images/ReportList.png'},
    {title: 'Chercher un rapport', short: 'Chercher un rapport', src: '../assets/images/ReportListSearch.png'},
    {title: 'Gérer les accès', short: 'Gérer les accçàs à mes rapports', src: '../assets/images/ReportAccess.png'},
    {title: 'Gérer les prescripteurs', short: 'Gérer les prescripteurs', src: '../assets/images/ManageOrl.png'},
];
  
  constructor(config: NgbCarouselConfig) {
    config.interval = 5000;
    config.keyboard = true;
    config.pauseOnHover = true;
  }
}

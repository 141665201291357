<div>
  <nav class="navbar navbar-expand">
    <div class="navbar-nav mr-auto">
      <img src="../assets/images/audition.png" />
      AudioReport - L'outil pour gérer vos rapports audiométriques
    </div>
  </nav>
  <div class="row">
    <div class="container col-12 center-div">
      <div class="presentation">
        AudioReport est un outil vous permettant de gérer votre rapport audiométrique.
      </div>
      <div class="description">
        Il permet de saisir, sauvegarder et exporter en PDF vos rapports.
        <br />
        Vous aurez la possibilité de partager vos rapports avec vos collaborateurs en lecture ou écriture
        <br />
        Vous pourrez gérez vos prescripteurs et les informer par mail
      </div>
    </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="presentation">
            Quelques écrans
          </div>
          <div class="center-div">
            <ngb-carousel>
              <ng-template ngbSlide *ngFor="let image of images">
                <div class="wrapper">
                  <img [src]="image.src" alt="Random first slide" class="{{ image.class }}">
                </div>
                <div class="carousel-caption">
                  <h2 style="color:  rgb(47, 112, 103);">{{ image.title }}</h2>
                </div>
              </ng-template>
            </ngb-carousel>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="presentation">
            La tarification
            <div class="center-div">
              <div class="card">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div>
                      15€ par utilisateur pour un mois
                    </div>
                    <div>
                      11€ par utilisateur pour 6 mois
                    </div>
                    <div>
                      8€ par utilisateur pour 1 an
                    </div>
                    <p>
                      Tarif dégressif à partir de 5 utilisateurs pour une même entité
                    </p>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
    </div>
    <footer>
      Pour tous renseignements envoyez moi <a href="mailto:nicolas@zalynh.fr">un mail</a> / <a target="_blank" href="/assets/CGU_AudioReport.pdf">Nos Cgu</a>
    </footer>
  </div>